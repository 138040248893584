<template>
  <div class="image-table">
    <div class="row">
      <div class="col-md-3">
        <div>
          <img
            class="img-fluid"
            src="@/assets/image_table/1.jpg"
            alt="table-image-1"
          />
        </div>
        <h5>Aplikasi di Minimarket</h5>
      </div>
      <div class="col-md-3">
        <div>
          <img
            class="img-fluid"
            src="@/assets/image_table/2.jpg"
            alt="table-image-2"
          />
        </div>
        <h5>Aplikasi di Toko Besi</h5>
      </div>
      <div class="col-md-3">
        <div>
          <img
            class="img-fluid"
            src="@/assets/image_table/3.jpg"
            alt="table-image-3"
          />
        </div>
        <h5>Aplikasi di Distro</h5>
      </div>
      <div class="col-md-3">
        <div>
          <img
            class="img-fluid"
            src="@/assets/image_table/4.jpg"
            alt="table-image-4"
          />
        </div>
        <h5>Aplikasi Isi Pulsa</h5>
      </div>
      <div class="col-md-3">
        <div>
          <img
            class="img-fluid"
            src="@/assets/image_table/5.jpg"
            alt="table-image-5"
          />
        </div>
        <h5>Aplikasi di Bengkel</h5>
      </div>
      <div class="col-md-3">
        <div>
          <img
            class="img-fluid"
            src="@/assets/image_table/6.jpg"
            alt="table-image-6"
          />
        </div>
        <h5>Aplikasi di Struk</h5>
      </div>
      <div class="col-md-3">
        <div>
          <img
            class="img-fluid"
            src="@/assets/image_table/7.jpg"
            alt="table-image-7"
          />
        </div>
        <h5>Aplikasi di Apotek</h5>
      </div>
      <div class="col-md-3">
        <div>
          <img
            class="img-fluid"
            src="@/assets/image_table/8.jpg"
            alt="table-image-8"
          />
        </div>
        <h5>Aplikasi di Hotel</h5>
      </div>
      <div class="col-md-3">
        <div>
          <img
            class="img-fluid"
            src="@/assets/image_table/9.jpg"
            alt="table-image-9"
          />
        </div>
        <h5>Aplikasi di Cafe</h5>
      </div>
      <div class="col-md-3">
        <div>
          <img
            class="img-fluid"
            src="@/assets/image_table/10.jpg"
            alt="table-image-10"
          />
        </div>
        <h5>Aplikasi Konter HP</h5>
      </div>
      <div class="col-md-3">
        <div>
          <img
            class="img-fluid"
            src="@/assets/image_table/11.jpg"
            alt="table-image-11"
          />
        </div>
        <h5>Aplikasi Loundry</h5>
      </div>
      <div class="col-md-3">
        <div>
          <img
            class="img-fluid"
            src="@/assets/image_table/12.jpg"
            alt="table-image-12"
          />
        </div>
        <h5>Aplikasi Parkir</h5>
      </div>
      <div class="col-md-3">
        <div>
          <img
            class="img-fluid"
            src="@/assets/image_table/13.jpg"
            alt="table-image-13"
          />
        </div>
        <h5>Aplikasi SMS/Telegram</h5>
      </div>
      <div class="col-md-3">
        <div>
          <img
            class="img-fluid"
            src="@/assets/image_table/14.jpg"
            alt="table-image-14"
          />
        </div>
        <h5>Aplikasi Akunting</h5>
      </div>
      <div class="col-md-3">
        <div>
          <img
            class="img-fluid"
            src="@/assets/image_table/15.jpg"
            alt="table-image-15"
          />
        </div>
        <h5>Aplikasi Salon</h5>
      </div>
      <div class="col-md-3">
        <div>
          <img
            class="img-fluid"
            src="@/assets/image_table/16.jpg"
            alt="table-image-16"
          />
        </div>
        <h5>Aplikasi Klinik</h5>
      </div>
      <div class="col-md-3">
        <div>
          <img
            class="img-fluid"
            src="@/assets/image_table/17.jpg"
            alt="table-image-17"
          />
        </div>
        <h5>Aplikasi Gajih</h5>
      </div>
      <div class="col-md-3">
        <div>
          <img
            class="img-fluid"
            src="@/assets/image_table/18.jpg"
            alt="table-image-18"
          />
        </div>
        <h5>Aplikasi Koperasi</h5>
      </div>
      <div class="col-md-3">
        <div>
          <img
            class="img-fluid"
            src="@/assets/image_table/19.jpg"
            alt="table-image-19"
          />
        </div>
        <h5>Aplikasi Rental</h5>
      </div>
      <div class="col-md-3">
        <div>
          <img
            class="img-fluid"
            src="@/assets/image_table/20.jpg"
            alt="table-image-20"
          />
        </div>
        <h5>Aplikasi Fitnes</h5>
      </div>
      <div class="col-md-3">
        <div>
          <img
            class="img-fluid"
            src="@/assets/image_table/21.jpg"
            alt="table-image-21"
          />
        </div>
        <h5>Aplikasi Show Room</h5>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>
