<template>
  <div class="home">
    <Slider />
    <HomeAbout />
    <ImageTable />
  </div>
</template>

<script>
import Slider from '@/components/home/slider.vue'
import HomeAbout from '@/components/home/about.vue'
import ImageTable from '@/components/home/imagetable.vue'

export default {
  components: {
    Slider,
    HomeAbout,
    ImageTable,
  },
}
</script>
