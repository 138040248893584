<template>
  <div class="download">
    <div class="klik">
      <h3 class="mb-3">Ruang Lingkup Pekerjaan</h3>
      <div class="row">
        <div class="col-md-6">
          <p>
            Software ini dapat digunakan untuk usaha pengisian pulsa All
            Operator, Modul Aplikasi untuk Pengisian/Topup Pulsa, PLN/Token,
            Shopee, Link, Gojek, BPJS, Voucher Game dst
          </p>
          <p>
            <a
              class="link"
              href="https://mega.nz/folder/F5VhTIAD#TTB4RUkYwSJpaE4kUfoXWA"
              target="_balnk"
            >
              Download Software Klik
            </a>
          </p>
          <p>
            <a
              class="link"
              href="https://mega.nz/folder/ssUjEKBI#myPD3qZ29jJzDW4vJdeByQ"
              target="_balnk"
            >
              Versi Lama (Update Manual)
            </a>
          </p>
        </div>
        <div class="col-md-6 text-center">
          <img class="img-fluid" src="@/assets/slider/1.jpg" alt="home-about" />
        </div>
      </div>
    </div>

    <div class="software">
      <img class="img-fluid" src="@/assets/software.jpg" alt="software" />
    </div>

    <div class="klik">
      <h3 class="mb-3">Download Software Umum</h3>
      <div class="row">
        <div class="col-md-6">
          <p>
            silahkan download software umum untuk berbagai usaha yang anda
            butuhkan :
          </p>
          <p>
            download
            <a
              class="link"
              href="https://drive.google.com/open?id=0BytWe05l6C1WQXROZ09qekZmNFk"
              target="_balnk"
            >
              klik disini
            </a>
            kumpulan versi baru (2017)
          </p>
          <p>
            download
            <a
              class="link"
              href="https://drive.google.com/open?id=0BytWe05l6C1WNXBoTkF2dzlZSHM"
              target="_balnk"
            >
              klik disini
            </a>
            kumpulan versi lama (1998-2016)
          </p>
        </div>
        <div class="col-md-6 text-center">
          <img class="img-fluid" src="@/assets/slider/4.jpg" alt="home-about" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>
