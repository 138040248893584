import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Download from '../views/Download.vue'
import DaftarHarga from '../views/DaftarHarga.vue'
import DaftarKlien from '../views/DaftarKlien.vue'
import LainLain from '../views/LainLain.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { auth: true, title: 'Home' },
  },
  {
    path: '/download',
    name: 'Download',
    component: Download,
    meta: { auth: true, title: 'Download' },
  },
  {
    path: '/daftar-harga',
    name: 'daftar-harga',
    component: DaftarHarga,
    meta: { auth: true, title: 'Daftar Harga' },
  },
  {
    path: '/daftar-klien',
    name: 'daftar-klien',
    component: DaftarKlien,
    meta: { auth: true, title: 'Daftar Klien' },
  },
  {
    path: '/lain-lain',
    name: 'lain-lain',
    component: LainLain,
    meta: { auth: true, title: 'Lain Lain' },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.afterEach((to) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title + ' | Nira Software'
  }
})

export default router
