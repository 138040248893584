<template>
  <div class="nira-header">
    <div class="media">
      <img
        src="@/assets/laptop-with-arrow.svg"
        class="mr-3 nira-laptop"
        alt="laptop"
      />
      <div class="media-body">
        <h5 class="mt-0">Nira Software</h5>
        Multi Nira Citra Soft.Sejak 1998
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>
