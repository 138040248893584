<template>
  <div class="lain-lain">
    <div class="lain">
      <h3 class="mb-3">Kontak Kami : WA/Telepon</h3>
      <div class="row">
        <div class="col-md-6">
          <img
            class="img-fluid contact"
            src="@/assets/contact.jpg"
            alt="home-about"
          />
        </div>
        <div class="col-md-6">
          <p>
            <img class="img-fluid chat" src="@/assets/chat.svg" alt="face1" />
            cs8 : 62 811-2086-878
          </p>
          <p>
            <img class="img-fluid chat" src="@/assets/chat.svg" alt="face1" />
            Dewi : 6285295454721 atau <br />
            081809544909
          </p>
          <p>
            <img class="img-fluid chat" src="@/assets/chat.svg" alt="face1" />
            Kantor : 62 811-2086-878
          </p>

          <ul class="lain-list">
            <li>
              Tidak Puas?, Pengaduan Pelayanan CS Kami Hub.Telp/WA :
              6281809544909
            </li>
            <li>
              Tidak Ada Software yang Anda Cari? Hubungi Kami untuk diadakan.
            </li>
            <li>
              Tidak Cocok dengan Software yang ada?, Hubungi Kami untuk
              disesuaikan dengan kebutuhan dan keinginan&nbsp;
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="content">
      <h3 class="mb-3">Cara Instalasi</h3>
      <p>
        Cara instalasi aplikasi bangun server pengisian pulsa/topup shopee,
        gojek pln dapat dilihat di
        <a
          href="https://kamaludindj.blogspot.com/2021/05/instalasi-aplikasi-transaksi-eletronik.html"
          target="_blank"
          >sini</a
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {}
</script>
