<template>
  <div class="about">
    <h3 class="mb-3">Ruang Lingkup Pekerjaan</h3>
    <div class="row">
      <div class="col-md-6 text-center">
        <img class="img-fluid" src="@/assets/home_about.jpg" alt="home-about" />
      </div>
      <div class="col-md-6">
        <ol>
          <li>
            Jasa Pembutan software Multi OS (Window, Android, Ios), baik Desktop
            base maupun webbase
          </li>
          <li>Jasa Perbaikan software</li>
          <li>Pelatihan Pembuatan dan Perbaikan software&nbsp;</li>
          <li>
            Pembuatan Aplikasi berbasis SMS/Telegram/Jabber (bisa id sender
            bukan nomor hp sbg pengirim), telegram dll
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
