<template>
  <div class="daftar-klien">
    <div class="klein">
      <h3 class="mb-3">Software Pengisian Pulsa</h3>
      <div class="row">
        <div class="col-md-6">
          <img class="img-fluid" src="@/assets/slider/3.jpg" alt="home-about" />
        </div>
        <div class="col-md-6">
          <p>
            Andalas (lembang); Gathan Pulsa (Majalengka); Hens Cellular
            (Bandung); Mena Cell (Bandung); SND BLACKBERRY (Bandung); RAZKA
            PULSA (Baleendah); HASANAH PULSA (Cirebon); AIR PORT (LOMBOK NTB);
            PLANET KOMUNIKA (Bandung); GREENTOP CELL (Bandung); BJCELL
            (Jakarta); Priangan Cell (Purwakarta); DERASA CELL (Bandung); Ally
            Cell (Bandung); IQ Komunika (Bandung); Hoki Tronik (Padang);
            Indopulsa (Surabaya); Pacman Cell (Bandung); Atin Pulsa (Bandung);
            Dinasti (Bandung); Rumah Pulsa (Sumedang); Hisyam Reload (Bandung);
            R'tak (Lembang); Niagara (Majalaya); Warung Cell (Bandung); Bea
            Komunika (Garut);Pelangi Cell (Bandung); Viu Cell (Bandung); Fna
            Reload (Jakarta);Millenium Cell (Padalarang)
          </p>
        </div>
      </div>
    </div>
    <div class="klein">
      <h3 class="mb-3">Software Apotek/Klinik</h3>
      <div class="row">
        <div class="col-md-6">
          <img class="img-fluid" src="@/assets/klien/2.jpg" alt="home-about" />
        </div>
        <div class="col-md-6">
          <p>
            Klinik Pratama Bhakti Sehat (Bandung); Klinik Pratama Amanah
            (Bandung); SALON VETRA (Majalaya); Klinik Citra Medika Padasuka
            (Bandung); Klinik SDC (Medan); APOTEK BUKIT PERMATA (Ciumbuleuit);
            APOTEK BINTANG (Bandung); Apotek Yalisa Farma (Cirebon); APOTEK
            SAHABAT SEJAHTERA (Bandung); Apotek Labora (Bandung); Apotek Surya
            (Bandung); APOTEK MEGA (Bandung); RSUD Salamun (Bandung); Apotek
            Batu Permata (Bandung); Apotek Permata Kasih (Bandung);
          </p>
        </div>
      </div>
    </div>
    <div class="klein">
      <h3 class="mb-3">Software Toko/Minimarket</h3>
      <div class="row">
        <div class="col-md-6">
          <img class="img-fluid" src="@/assets/slider/2.jpg" alt="home-about" />
        </div>
        <div class="col-md-6">
          <p>
            BMT Taawun (Sumedang); Saluyu Putra (Bandung); Toko Saluyu
            (Bandung); Toko Kue Zulfa (Bandung); Stars Oto Parts (Bandung);
            Nindy Minimarket (Bandung); Toko Sinar Baru (Bandung); TOKO JHL
            (Bandung); TOKO JHB (Bandung); PROCOM ACCESSORIES (Padalarang); NAFA
            COSMETIC'S AND BABY NEEDS (UJUNGBERUNG); TOKO IRAWAN (Cilacap); NUSA
            INDAH COLLECTION (Bandung); TB. KARYA JAYA (Bandung); TOKO JHAFIR
            (Bandung); Bless Games (Bandung); JHR.O (Bandung); Sindoro
            Minimarket (Wonogiri); Sinar Mas Icons (Bandung); Toko Primer
            Koperasi Kartika Manunggal (Padalarang); Toko Queen (Bandung);
            Pasundan Jati Baja (Cimindi); Toko Besi BSL (Cimindi); Toko Rhema
            Jaya (Semarang);CV Nusa Persada (Bandung); SMK MUHAMMADIYAH
            (Garut);Toko Anugerah (Bandung);PB. EKA JAYA PUTRA (Bandung);ANUGRAH
            SP MART (Bandung);
          </p>
        </div>
      </div>
    </div>
    <div class="klein">
      <h3 class="mb-3">Software Lain-Lainya</h3>
      <div class="row">
        <div class="col-md-6">
          <img class="img-fluid" src="@/assets/klien/3.jpg" alt="home-about" />
        </div>
        <div class="col-md-6">
          <p>
            SMK SUMATRA 40 (Bandung); PETER SAY'S DENIM (Bandung); Primer
            Koperasi Kartika Manunggal (Padalarang); Owners Worldwide (Bekasi);
            KSP USAHA MANDIRI (Bandung);Livigi Sport (Cimahi); UTAMA MOTOR
            (Bandung); STIKES 'AISYIYAH (Bandung); PT.BINA PUTRA LIBRA
            (Bandung); Ambbasador (Bandung); BINA MOTOR (Bandung); LEOPARD
            DESIGN & CUSTOMIZE FURNITURE (Banten);LAVIDATEX (Kopo); Sentral
            Biliar & Karaoke (Bandung); Ridho Motor (Majalengka);KALIMASADA
            (Bandung); Fajar Motor (Majalengka);Mie Baso AA (Nusa Tenggara
            Timur);
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>
