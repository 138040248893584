<template>
  <div id="app">
    <div class="container nira-container">
      <Hearder />
      <Navbar />
      <router-view />

      <div class="copyright">
        <p>Copyright &copy; Nira Software</p>
      </div>
    </div>
  </div>
</template>

<script>
import Hearder from '@/components/layout/hearder'
import Navbar from '@/components/layout/navbar'

export default {
  components: {
    Hearder,
    Navbar,
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  background-image: url('./assets/skyline.png');
  background-color: #fff;
  background-repeat: repeat-x;
  background-position: 0 0;
  background-attachment: scroll;
  background-size: auto;
  color: #000;
  /* color: #2c3e50; */
}

.nira-container {
  background-color: #fff;
  max-width: 980px;
  padding: 0;
  margin-top: 0;
  margin-bottom: 50px;
  border-top: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  border-left: 0 solid rgba(0, 0, 0, 0.15);
  border-right: 0 solid rgba(0, 0, 0, 0.15);
  -webkit-border-top-right-radius: 0;
  -moz-border-radius-topright: 0;
  border-top-right-radius: 0;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topleft: 0;
  border-top-left-radius: 0;
  -webkit-border-bottom-right-radius: 10px;
  -moz-border-radius-bottomright: 10px;
  border-bottom-right-radius: 10px;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius-bottomleft: 10px;
  border-bottom-left-radius: 10px;
  -webkit-box-shadow: 0 0 24px rgb(0 0 0 / 80%);
  -moz-box-shadow: 0 0 24px rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 24px rgb(0 0 0 / 80%);
  display: block;
  margin-left: auto;
  margin-right: auto;
  overflow-x: hidden;
}

/* #nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
} */
</style>
