<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <a class="navbar-brand" href="#">Nira</a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNavDropdown"
      aria-controls="navbarNavDropdown"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav">
        <li class="nav-item" @click="hideNavbarCollapse()">
          <router-link class="nav-link" to="/"> Home </router-link>
        </li>
        <li class="nav-item" @click="hideNavbarCollapse()">
          <router-link class="nav-link nav-hide-collapse" to="/download"
            >Download</router-link
          >
        </li>
        <li class="nav-item" @click="hideNavbarCollapse()">
          <router-link class="nav-link nav-hide-collapse" to="/daftar-harga">
            Daftar Harga
          </router-link>
        </li>
        <li class="nav-item" @click="hideNavbarCollapse()">
          <router-link class="nav-link nav-hide-collapse" to="/daftar-klien">
            Daftar Klien
          </router-link>
        </li>
        <li class="nav-item" @click="hideNavbarCollapse()">
          <router-link class="nav-link nav-hide-collapse" to="/lain-lain">
            Lain Lain
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import $ from 'jquery'

export default {
  methods: {
    hideNavbarCollapse() {
      $('.navbar-collapse').collapse('hide')
    },
  },
}
</script>
