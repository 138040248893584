<template>
  <div class="daftar-harga">
    <div class="head">
      <h3 class="mb-4">Layanan &amp; Maintenance</h3>
      <h3>
        Tersedia mulai Paket Gratis dan Berbayar, bisa sesuai dengan pesana dan
        kebutuhan usaha Anda.
      </h3>
    </div>

    <div class="daftar-harga-table">
      <!-- <img
        class="img-fluid"
        src="@/assets/daftar_harga.jpg"
        alt="daftar-harga"
      /> -->
      <div class="table-responsive">
        <table class="table table-striped table-bordered">
          <thead>
            <tr>
              <th></th>
              <th>Community</th>
              <th>Pro 1</th>
              <th>Pro 2</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Cost</th>
              <td>0</td>
              <td>3k/day</td>
              <td>0</td>
            </tr>
            <tr>
              <th>Admin/dashbord</th>
              <td>v</td>
              <td>v</td>
              <td>v</td>
            </tr>
            <tr>
              <th>GSM, Telegram, Jabber/Xmpp</th>
              <td>v</td>
              <td>v</td>
              <td>v</td>
            </tr>
            <tr>
              <th>Multi H2H *</th>
              <td>v</td>
              <td>v</td>
              <td>v</td>
            </tr>
            <tr>
              <th>Multi Dial/SMS/Menu</th>
              <td>v</td>
              <td>v</td>
              <td>v</td>
            </tr>
            <tr>
              <th>Auto Fisik Voucher</th>
              <td>v</td>
              <td>v</td>
              <td>v</td>
            </tr>
            <tr>
              <th>Auto deposit BCA</th>
              <td>-</td>
              <td>v</td>
              <td>v</td>
            </tr>
            <tr>
              <th>Mkios</th>
              <td>-</td>
              <td>v</td>
              <td>v</td>
            </tr>
            <tr>
              <th>Mobo</th>
              <td>-</td>
              <td>v</td>
              <td>v</td>
            </tr>
            <tr>
              <th>Tri</th>
              <td>-</td>
              <td>v</td>
              <td>v</td>
            </tr>
            <tr>
              <th>Dompul</th>
              <td>-</td>
              <td>-</td>
              <td>v</td>
            </tr>
            <tr>
              <th>Merek/Name Sendiri</th>
              <td>-</td>
              <td>-</td>
              <td>v</td>
            </tr>
            <tr>
              <th>Kastem sesuai Kebutuhan</th>
              <td>-</td>
              <td>-</td>
              <td>v</td>
            </tr>
            <tr>
              <th>Unit CPU (rental)</th>
              <td>call</td>
              <td>call</td>
              <td>call</td>
            </tr>
            <tr>
              <th>Layanan online</th>
              <td>premium</td>
              <td>free</td>
              <td>free</td>
            </tr>
            <tr class="empty">
              <th></th>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr class="text-center">
              <th colspan="4">
                (Terhubung ke Server TVEF, Otomax, IRS, VRE, ARM19, MaxiSoft,
                Voucha, 3Gmac)
              </th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="server">
      <h3 class="mb-3">Tersedia hardware unit di sewakan</h3>
      <div class="row">
        <div class="col-md-6">
          <img class="img-fluid" src="@/assets/server.jpg" alt="home-about" />
        </div>
        <div class="col-md-6">
          <p>
            Jika ada kesulitan dan instalasi aplikasi ini bisa menghubungi sdri
            Fani : 62 811-2086-878, Jika Anda tidak memiliki unit hardware
            (komputer), kami siapkan hardware untuk di sewa, mulai 100 rb
            perbulan tergantung spesifikasi yang disewa. biaya sewa ini sudah
            termasuk biaya listrik, internet, IP tetap, anda tingal pakai dan
            maintenance.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>
