<template>
  <div class="slider">
    <div class="head">
      <h3 class="mb-4">Layanan</h3>
      <h3>
        Instalasi Aplikasi Transaksi Eletronik (Topup Pulsa, Token PLN, Shopee,
        Voucher Game DLL).
      </h3>
    </div>

    <div
      id="niraSoftware"
      class="carousel slide carousel-fade"
      data-ride="carousel"
    >
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img
            src="@/assets/slider/1.jpg"
            class="d-block w-100"
            alt="slider1"
          />
        </div>
        <div class="carousel-item">
          <img
            src="@/assets/slider/2.jpg"
            class="d-block w-100"
            alt="slider2"
          />
        </div>
        <div class="carousel-item">
          <img
            src="@/assets/slider/3.jpg"
            class="d-block w-100"
            alt="slider3"
          />
        </div>
        <div class="carousel-item">
          <img
            src="@/assets/slider/4.jpg"
            class="d-block w-100"
            alt="slider4"
          />
        </div>
      </div>
      <a
        class="carousel-control-prev"
        href="#niraSoftware"
        role="button"
        data-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a
        class="carousel-control-next"
        href="#niraSoftware"
        role="button"
        data-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  mounted() {
    $('.carousel').carousel({
      interval: 3000,
    })
  },
}
</script>
